import axios from "axios";

const baseUrl = 'https://rfp-s.theraise.app'; 
const planCode = 'wSMJSI';

function registerVoucher(name, phone, age) {
    return axios.post(baseUrl + '/rfp/regisvoucher/create', {
        "regisvoucher": {
            "name": name,
            "phoneNumber": phone,
            "planCode": planCode,
            "options": {
                "age": `${age}`,
                // "kidAge": `${kidAge}`
            }
          }
    }, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(response => {
        if (response.status == 200) {
            // return voucher
            return response.data.voucher;
        } 
    }).catch((ex) => {
        if (ex.response!= undefined && ex.response.data != undefined) {
            if (ex.response.data.error == '406' && ex.response.data.error_despcription == "phoneNumber_registered") {
                throw new Error('Khách hàng đã đăng ký nhận mã quà tặng. Vui lòng sử dụng tài khoản khác.');
            } else if (ex.response.data.error == '406' && ex.response.data.error_despcription == "startDate") {
                throw new Error('Chương trình chưa bắt đầu, vui lòng quay lại sau.');
            } else if (ex.response.data.error == '507' && ex.response.data.error_despcription == "code_not_exist") {
                throw new Error('Số lượng mã quà tặng đã hết. Vui lòng quay lại sau.​');
            }
            throw new Error('Đã có lỗi xảy ra, vui lòng thử lại');
        } else if (ex.code == "ERR_NETWORK"){
            throw new Error('Không thể kết nối tới server');
        } else {
            throw new Error('Đã có lỗi xảy ra, vui lòng thử lại');
        }
        
        
    });
}
export const rfpService = {
    registerVoucher
}