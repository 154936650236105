<template>
    <div class="container">
        <div class="row">
            <div class="col-12 ">
                <a href="https://theraise.app/raise-for-parents-2023/" style="">Raise for Parents</a>
            </div>
            <div style="height: 100px;"></div>
            <div class="col-12 ">
                <img src="images/raise_for_partent.png" style="max-width: 100%;">
            </div>
        </div>
        <div style="height: 100px;"></div>

        <div class="row" style="justify-content: center; align-items: center;">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <img src="images/raise.png" style="max-width: 100%; height: 80px;">
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <img src="images/modilac.png" style="max-width: 100%;height: 80px;">
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <img src="images/teachforVN.png" style="max-width: 100%;height: 80px;">
            </div>
        </div>
        <div style="height: 100px;"></div>
        <div class="row" style="justify-content: center; align-items: center;">
            <div class="col-12">
                <img src="images/title.png" style="max-width: 100%;">
            </div>
            <div style="height: 50px;"></div>

            <div class="col-12 col-md-12 col-lg-9">
                <form>
                    <div class="mb-12">
                        <input class="input-form" type="text" placeholder="Họ và tên (bắt buộc)" v-model="state.name">
                        <div class="error" v-for="error of v$.name.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </div>
                    </div>
                    <div class="mb-12">
                        <input class="input-form" type="text" placeholder="Số điện thoại (bắt buộc)" v-model="state.phone">
                        <div class="error" v-for="error of v$.phone.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </div>
                    </div>
                    <div class="mb-12">
                        <input class="input-form" type="number" placeholder="Tuổi của bạn (bắt buộc)" v-model="state.age">
                        <div class="error" v-for="error of v$.age.$errors" :key="error.$uid">
                            {{ error.$message }}
                        </div>
                    </div>
                    <!-- <div class="mb-12">
                        <input class="input-form" type="number" placeholder="Tuổi của con bạn" v-model="state.kidAge">
                    </div> -->
                    <div>
                        <p class="good-luck">Chúc bạn may mắn!</p>
                        <button type="submit" class="send" @click="submitData" :disabled="(v$.$invalid)">Gửi</button>
                        <loading v-model:active="isLoading" :is-full-page="true" loader="dots" color="#000072" width="70" height="70" />
                        <BModal @hidden="closeDialog" v-model="showModal" hide-footer="true" no-close-on-backdrop="true" centered 
                                    :header-text-variant="headerTextVariant">
                            <div class="position-relative">
                                <div v-if="errorMessage == null">
                                    <img src="images/gift-code.png">
                                    <div class="vouncherText">{{ voucher }}</div>
                                    <img src="images/copy.png" class="copyButton" v-clipboard="voucher"/>
                                </div>
                                <div v-else>
                                    <img src="images/erros.png">
                                    <div class="errorMessageText">{{ errorMessage }}</div>
                                </div>
                            </div>
                        </BModal>
                    </div>
                </form>
            </div>
        </div>
        <div style="height: 100px;"></div>
        <div class="row">
            <div class="col-12">
                <p style="color: #fff;">Vui lòng theo dõi Facebook của Raise - Trợ lý ảo cho Cha Mẹ để cập nhật thông tin mới nhất</p>
                <div class="divider"></div>
            </div>
            <div style="height: 50px;"></div>
            <div class="col-12">
                <div class="row" style="justify-content: center;">
                    <div class="col-sm-2 col-md-1">
                        <img src="/images/website.png" id="link-1" onclick="openWebsite(1)">
                    </div>
                    <div class="col-sm-2 col-md-1">
                        <img src="/images/facebook.png" id="link-2" onclick="openWebsite(2)">
                    </div>
                    <div class="col-sm-2 col-md-1">
                        <img src="/images/instar.png" id="link-3" onclick="openWebsite(3)">
                    </div>
                    <div class="col-sm-2 col-md-1">
                        <img src="/images/mail.png" id="link-4" onclick="openWebsite(4)">
                    </div>
                    <div class="col-sm-2 col-md-1">
                        <img src="/images/linkind.png" id="link-5" onclick="openWebsite(5)">
                    </div>
                    <div class="col-sm-2 col-md-1">
                        <img src="/images/youtube.png" id="link-6" onclick="openWebsite(6)">
                    </div>
                </div>
                <div style="height: 30px;"></div>
                <div class="row" style="justify-content: center;">
                    <div class="col-sm-6 col-md-3">
                        <img src="/images/google.png" id="link-7" onclick="openWebsite(7)">
                    </div>
                    <div class="col-sm-6 col-md-3">
                        <img src="/images/apple.png" id="link-8" onclick="openWebsite(8)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from '@vuelidate/validators';
import { reactive, computed } from 'vue';
import { BModal } from 'bootstrap-vue-next';
import {rfpService} from '../services/rfp.service';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

function isVietnamesePhoneNumberValid(value) {
            const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
            return value.match(regexPhoneNumber) ? true : false;
        }


export default ({
    name: 'GetVoucher',
    setup() {
        const initState = {
            name: '',
            phone: '',
            age: '',
            kidAge: ''
        }

        const state = reactive({...initState});

        function resetForm() {
            Object.assign(state, initState);
        }

        const validPhone = (value) => isVietnamesePhoneNumberValid(value);

        const rules = computed(() => {
            return {
                name: { 
                    required: helpers.withMessage('Vui lòng nhập Họ tên của bạn', required),
                    $autoDirty: true 
                },
                phone: {
                    required: helpers.withMessage('Vui lòng nhập số điện thoại của bạn', required),
                    validPhone: helpers.withMessage('Số điện thoại không hợp lệ', validPhone),
                    $autoDirty: true 
                },
                age: { 
                    required: helpers.withMessage('Vui lòng nhập tuổi của bạn', required),
                    $autoDirty: true 
                },
            }
        })

        const v$ = useVuelidate(rules, state)

        return { state, v$, resetForm }
    },
    components: {
        BModal, Loading
    },
    data() {
        return {
            showModal: false,
            voucher: null,
            errorMessage: null,
            variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
            headerTextVariant: 'light',
            isLoading: false,
        }
    },
    methods: {
        async submitData(e)  {
            e.preventDefault();
            try {
                this.isLoading = true;
                // submit to server
                this.voucher = await rfpService.registerVoucher(this.state.name, this.state.phone, this.state.age, this.state.kidAge);
            } catch (ex) {
                this.errorMessage = ex.message;
            } finally {
                this.isLoading = false;
            }
            this.showModal = true;
        },
        closeDialog() {
            this.resetForm();
            this.v$.$reset();
        }

        
    }
})
</script>