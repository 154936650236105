import { createApp } from 'vue'
import App from './App.vue';
import BootstrapVueNext from 'bootstrap-vue-next'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import {LoadingPlugin} from 'vue-loading-overlay';

createApp(App).use(BootstrapVueNext).use(VueClipboard).use(LoadingPlugin)
.mount('#app')
